import { Atom } from ":mods";
import { AStageModule } from "../models";

export function createModuleInputs(stageID: string | number, details?: AStageModule) {
  const ID = Atom.Form.createInput(undefined, { disabled: true });
  const Stage = Atom.Form.createInput(stageID);
  const Name = Atom.Form.createInput(details?.name, { required: true });
  const Description = Atom.Form.createInput(details?.description, { required: true });
  const TotalMinutesVideo = Atom.Form.createInput(details?.total_minutes_video);
  const TotalMinutesReading = Atom.Form.createInput(details?.total_minutes_reading);
  const TotalMinutesQuestions = Atom.Form.createInput(details?.total_minutes_questions);
  const TotalMinutesTasks = Atom.Form.createInput(details?.total_minutes_tasks);

  const Actions = Atom.Form.createFormActions(
    {
      ID,
      Stage,
      Name,
      Description,
      TotalMinutesVideo,
      TotalMinutesReading,
      TotalMinutesQuestions,
      TotalMinutesTasks,
    },
    {
      defaultCase: "snake",
    }
  );

  // const values = Actions.getValuesUppercase({TotalMinutesQuiz: "kebab", TotalMinutesVideo: "snake"});
  return {
    Actions,
    ID,
    Stage,
    Name,
    Description,
    TotalMinutesVideo,
    TotalMinutesReading,
    TotalMinutesQuestions,
    TotalMinutesTasks,
  };
}
